<template>
  <div class="area-tour-project">
    <div class="column-container">
      <img :src="adimg" class="ad-img" v-if="false" />
      <CpnTitle icon="tt-6" more="/brandPicList?brandType=5"
        >避暑旅游目的地
      </CpnTitle>
      <CpnProject :list="list"></CpnProject>
    </div>
  </div>
</template>
<script>
import CpnTitle from '@/components/view/title/CpnTitle.vue'
import CpnProject from '@/components/view/project/CpnProject.vue'
import { Info_brandProductList } from '@/info/infoHome.js'
export default {
  inject: [],
  components: { CpnTitle, CpnProject },
  props: {},
  data() {
    return {
      adimg: require('@/assets/img/home/ad-2.jpg'),
      year: {
        options: [],
        value: ''
      },
      list: [],
      bodyWidth: document.documentElement.clientWidth
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.initYear()
    this.getData()
  },
  methods: {
    initYear() {
      let now = new Date().Format('YYYY')
      for (let i = 0; i < 10; i++) {
        this.year.options.push({ name: now - i + '年', value: now - i })
      }
    },
    getData() {
      Info_brandProductList({
        brandType: 5,
        pageNum: 1,
        pageSize: 4,
        year: this.year.value
      }).then((ret) => {
        this.list = ret?.list.map((e) => {
          if (e.imgs) {
            let imgs = this.Api.jsonData(e.imgs)
            let img = imgs[0]?.url
            return { ...e, img: img ? this.Api.getImgPath() + img : '' }
          } else {
            return { ...e, img: e.url }
          }
        })
      })
    },
    initTestData() {
      this.list = [
        {
          name: '新疆温泉县',
          content:
            '温泉县三面环山，有瑰丽的河谷风光，壮丽的瀑布、以及茂盛的草原，泉水清幽，如诗如画',
          img: require('@/assets/img/test/pic2.png')
        }
      ]
    },
    EventChange() {}
  }
}
</script>
<style lang="scss" scoped>
.area-tour-project {
  .ad-img {
    width: 100%;
    margin-bottom: 50px;
    transition: 0.3s ease-out;
    &:hover {
      transform: scale(1.05); /**hover放大图片 */
    }
  }
  .other {
    position: absolute;
    right: 50px;
    top: 0;
    display: flex;
    align-items: center;
    width: 100px;
  }
}
</style>
