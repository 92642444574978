import Store from '@/store'
class KOApi {
  /*
   *当前项目的处理
   */
  getBrandItem(type) {
    let dict = {}
    //detail:是否有详情
    let data = [
      { name: '中国天然氧吧', key: '3', path: '/cnoz', detail: true },
      { name: '中国气候宜居城市(县)', key: '4', path: '/cclc', detail: false },
      { name: '避暑旅游目的地', key: '5', path: '/ccsd', detail: false }
    ]
    for (let item of data) {
      dict[item.key] = item
    }
    return dict[type]
  }
  getKOStore() {
    return Store.state.info.KOStore
  }
  getAreaCode() {
    return this.getKOStore().GArea.value
  }
  getHostPath() {
    return process.env.VUE_APP_GATEWAY_SERVICE
  }
  getImgPath() {
    if (Store.state.info.infoConnect) {
      return process.env.VUE_APP_IMG_SERVICE //+ 'api/oss/redirect?file='
    } else {
      return this.host
    }
  }
  pdfRead() {
    return process.env.VUE_APP_IMG_SERVICE //+ 'api/oss/local/download?file='
  }
  getAppPath() {
    return 'https://zs.scnjw.com/'
  }
  getMGPath() {
    return process.env.VUE_APP_MG_SERVICE
  }
  imgPath(url) {
    if (url) {
      return this.getImgPath() + url
    } else {
      return ''
    }
  }
  getPdf(ret) {
    let url = ret?.replace(/(.doc$)|(.docx$)/g, '.pdf')
    return this.getImgPath() + url
  }
  openOffice(productId) {
    let link = document.createElement('a') // 创建a标签
    link.style.display = 'none'
    const hostPath = this.getHostPath()
    link.href = `javascript:POBrowser.openWindowModeless('${hostPath}/api/office/editWord?officeId=${productId}&authToken=${localStorage.getItem(
      'authToken'
    )}','width=1200px;height=800px;')` // 设置下载地址
    document.body.appendChild(link)
    link.click()
  }
  getMapConfig() {
    return {
      center: this.getKOStore().mapCenter, //[117, 32],
      zoom: 4.5,
      minZoom: 1,
      maxZoom: 18
    }
  }
}
export default KOApi
