<!--
 * @Name: 组件名
 * @Author: koyoyoo
 * @Date: 2024-05-29 14:36:39
-->
<template>
  <div class="cpn-brand-list">
    <template v-for="brand in list" :key="brand">
      <CpnItemBrand
        class="brand-box"
        :class="'brand-type-' + brand.brandType"
        v-for="item in brand.list"
        :key="item"
        :brandName="brand.brandName"
        :name="item.name"
        :publishTime="item.publishTime"
        @click="EventLink(item, brand.brandType)"
      ></CpnItemBrand>
    </template>
  </div>
</template>
<script>
import CpnItemBrand from '@/components/content/item/CpnItemBrand.vue'
export default {
  inject: [],
  components: { CpnItemBrand },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventLink(item, brandType) {
      if (brandType == 3) {
        this.Api.routerLink({ path: '/cnozDetail', query: { id: item.id } })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-brand-list {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 0 0;
  .brand-box {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
    margin-bottom: 20px;
    flex-basis: 33.33%;
    &.brand-type-3:hover {
      color: #33c3cc;
      cursor: pointer;
    }
  }
}
</style>
