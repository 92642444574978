<template>
  <div class="box-project">
    <div class="top-bar" v-if="false">
      <div class="left-column">
        <KOimg :src="firstItem?.img"></KOimg>
      </div>
      <div class="right-column">
        <div class="m-project-con">
          <div class="title">{{ firstItem?.name }}</div>
          <div class="line"></div>
          <div class="content">{{ firstItem?.introduce }}</div>
        </div>
      </div>
    </div>
    <div class="main-bar">
      <ul class="m-list" v-if="list?.length">
        <li
          v-for="item in list"
          :key="item"
          :class="type == 'video' ? 'li-video' : ''"
          @click="EventSelect(item)"
        >
          <KOimg :src="item.img"></KOimg>
          <div class="name">{{ item.name }}</div>
        </li>
      </ul>
      <div v-else class="ui-no-data">暂无数据</div>
    </div>
  </div>
</template>
<script>
export default {
  inject: [],
  components: {},
  props: {
    brandType: {
      type: [Number, String],
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    type: {
      type: [String],
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {
    firstItem() {
      return this.list[0]
    },
    nextItems() {
      return this.list
    }
  },
  mounted() {},
  methods: {
    EventSelect(item) {
      let brandItem = this.Api.getBrandItem(this.brandType)
      //避暑旅游地没有详情页
      if (brandItem?.path && item.id && brandItem.detail) {
        this.Api.routerLink({
          path: brandItem.path + 'Detail',
          query: {
            id: item.id
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.box-project {
  .top-bar {
    display: flex;
    margin-bottom: 20px;
    .left-column {
      flex-grow: 2;
      width: 10px;
      .ko-img {
        height: 400px;
        transition: 0.3s ease-out;
        &:hover {
          transform: scale(1.05); /**hover放大图片 */
          color: #23c4e0;
        }
      }
    }
    .right-column {
      width: 10px;
      flex-grow: 1;
      background: url('~@/assets/img/home/project-bg.jpg') center bottom/cover
        no-repeat;
      .m-project-con {
        padding: 100px 65px 0 65px;
        text-align: center;
        .title {
          font-size: 24px;
          margin-bottom: 55px;
        }
        .line {
          width: 65px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          margin-bottom: 50px;
          margin-left: auto;
          margin-right: auto;
        }
        .content {
          font-size: 14px;
          line-height: 1.6;
          color: #888;
        }
      }
    }
  }
  .m-list {
    display: flex;
    flex-wrap: wrap;
    li {
      position: relative;
      flex-basis: calc((100% - 60px) / 4);
      margin-right: 20px;
      margin-bottom: 20px;
      cursor: pointer;
      transition: 0.3s ease-out;
      &:hover {
        transform: scale(1.05); /**hover放大图片 */
        color: #23c4e0;
      }
      &.li-video::after {
        content: '';
        position: absolute;
        top: 40%;
        left: 50%;
        display: block;
        width: 73px;
        height: 73px;
        transform: translate(-50%, -50%);
        background: url('@/assets/img/icon/icon-video.png') center no-repeat;
      }
      .ko-img {
        height: 180px;
      }
      .name {
        padding: 15px 0;
        text-align: center;
        background: #fff;
        font-size: 18px;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
/*@media screen and (min-width: 2000px) {
  .box-project {
    .m-list {
      li {
        .ko-img {
          height: 240px;
        }
      }
    }
  }
}*/
@media screen and (max-width: 1200px) {
  .box-project {
    .m-list {
      li {
        .ko-img {
          height: 130px;
        }
        .name {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
