<template>
  <el-image class="ko-img" v-bind="$attrs" :preview-teleported="true">
    <template #error>
      <div class="image-slot">
        <el-image class="no-data-img" fit="contain" :src="noDataImg" alt="" />
      </div>
    </template>
    <template #placeholder>
      <div
        class="image-slot"
        v-loading="true"
        element-loading-background="rgba(0,0,0,0.4)"
      >
        <el-image class="no-data-img" fit="contain" :src="noDataImg" alt="" />
      </div>
    </template>
  </el-image>
</template>
<script>
export default {
  log: '',
  name: 'KOimg',
  components: {},
  props: {
    color: {
      type: String,
      default: 'rgba(255,255,255,.6)'
    }
  },
  data() {
    return {
      noDataImg: require('@/assets/img/no-data.png')
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.ko-img {
  display: block;
  ::v-deep(.el-image__wrapper) {
    .image-slot {
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
    }
    .el-loading-spinner {
      margin-top: 0;
      transform: translate(0, -50%);
      .path {
        stroke-linecap: round;
        stroke: rgba(255, 255, 255, 0.9);
      }
    }
    svg.img-svg {
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    font-size: 18px;
  }
  .no-data-img {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
</style>
