<template>
  <Swiper
    :slides-per-view="per"
    @swiper="initSwiper"
    :modules="modules"
    :autoplay="true"
    :navigation="true"
    :pagination="true"
    class="cpn-pro-list cpn-swiper"
  >
    <SwiperSlide v-for="item in list" :key="item" @click="EventOpen(item)">
      <KOimg fit="cover" :src="item.img"></KOimg>
      <div class="cont">
        <div class="text">{{ item.summary }}</div>
      </div>
    </SwiperSlide>
  </Swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/css/navigation'
export default {
  inject: [],
  components: { Swiper, SwiperSlide },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      per: 1,
      modules: [Navigation, Pagination, Autoplay]
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    initSwiper(swiper) {
      this.swiper = swiper
    },
    EventOpen(item) {
      this.Api.routerLink({
        path: '/book',
        query: { typeId: item.typeId, year: item.year }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-pro-list {
  display: flex;
  .swiper-slide {
    display: flex;
    cursor: pointer;
    .ko-img {
      width: 10px;
      flex-grow: 1.4;
      height: 200px;
      transition: 0.3s ease-out;
      &:hover {
        transform: scale(1.05); /**hover放大图片 */
      }
    }
    .cont {
      width: 10px;
      flex-grow: 1;
      padding: 20px 30px;
      background: #f2fdfe url('~@/assets/img/home/column-bg.jpg') center 95%/86%
        auto no-repeat;
      font-size: 16px;
      line-height: 1.6;
      color: #747474;
      text-align: justify;
      .text {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    @media screen and (max-width: 1200px) {
      .ko-img {
        height: 160px;
      }
      .cont {
        font-size: 14px;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  ::v-deep(.swiper-button-disabled) {
    display: none;
  }
  ::v-deep(.swiper-button-prev) {
    padding: 10px;
    left: 0;
  }
  ::v-deep(.swiper-button-next) {
    padding: 10px;
    right: 0;
  }
}
</style>
