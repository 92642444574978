<template>
  <div class="cpn-collapse">
    <dl
      :class="currentIndex == index ? 'hover' : 'default'"
      v-for="(item, index) in list"
      :key="item"
      @mouseenter="currentIndex = index"
    >
      <dt :class="'dt-' + index" v-html="getName(item, index)"></dt>
      <dd>
        <div class="lump-area" v-if="currentIndex == index" v-loading="loading">
          <CpnSwiperD class="m-province-list" :list="item.areas">
            <template #slide="slideD">
              <div class="m-collapse-area-list">
                <div class="title">
                  <div class="tt">{{ slideD.prop.title }}</div>
                  <div class="count">（{{ slideD.prop.list?.length }}）</div>
                </div>
                <div class="content">
                  <CpnSwiperYA
                    :list="slideD.prop.list"
                    @select="EventSelect_oxyItem"
                  >
                    <template #slide="slideYA">
                      <div class="address">
                        {{ slideYA.prop.productName }}
                      </div>
                    </template>
                  </CpnSwiperYA>
                </div>
              </div>
            </template>
          </CpnSwiperD>
        </div>
      </dd>
    </dl>
  </div>
</template>
<script>
import CpnSwiperD from '@/components/content/swiper/CpnSwiperD.vue'
import CpnSwiperYA from '@/components/content/swiper/CpnSwiperYA.vue'
export default {
  inject: [],
  components: { CpnSwiperD, CpnSwiperYA },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      currentIndex: 0,
      areaList: []
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    getName(item, index) {
      if (this.currentIndex == index) {
        return item.value + '年氧吧创建地区：' + item.number + '个'
      } else {
        return (
          '<span class="value">' +
          this.addBreaks(item.value) +
          '</span>' +
          this.addBreaks('年氧吧创建地区')
        )
      }
    },
    addBreaks(str) {
      return str.toString().split('').join('<br>')
    },
    EventSelect_oxyItem(ret) {
      this.Api.routerLink({ path: '/cnozDetail', query: { id: ret.id } })
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-collapse {
  display: flex;
  dl {
    position: relative;
    width: 10px;
    flex-grow: 1;
    transition: 0.3s ease-out;
    dt {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #fff;
      font-size: 16px;
      color: #444;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 300px;
      transition: 0.3s ease-out;
      overflow: hidden;
    }
    dd {
      height: 300px;
      overflow: hidden;
    }
    &:nth-child(1n) {
      dt {
        background: #9ddfeb url('@/assets/img/bg/history/li-bg-1.jpg')
          center/cover no-repeat;
      }
    }
    &:nth-child(2n) {
      dt {
        background: #ace4d7 url('@/assets/img/bg/history/li-bg-2.jpg')
          center/cover no-repeat;
      }
    }
    &:nth-child(3n) {
      dt {
        background: #a0dfe2 url('@/assets/img/bg/history/li-bg-3.jpg')
          center/cover no-repeat;
      }
    }
    &:nth-child(4n) {
      dt {
        background: #9ddfeb url('@/assets/img/bg/history/li-bg-4.jpg')
          center/cover no-repeat;
      }
    }
    &:nth-child(5n) {
      dt {
        background: #b7e9ee url('@/assets/img/bg/history/li-bg-5.jpg')
          center/cover no-repeat;
      }
    }
    &:nth-child(6n) {
      dt {
        background: #bdeae9 url('@/assets/img/bg/history/li-bg-6.jpg')
          center/cover no-repeat;
      }
    }
    &.hover {
      flex-grow: 8;
      dt {
        height: 50px;
        background: transparent;
        flex-direction: row;
        font-size: 18px;
      }
      dd {
        background: url('@/assets/img/bg/history/current-bg.jpg') center/cover
          no-repeat;
      }
    }
    &.default {
      dt {
        flex-direction: column;
      }
    }
  }
}
.lump-area {
  padding: 30px 0 10px;
  color: #333;
  border-radius: 2px;
}
.m-collapse-area-list {
  padding: 0 30px;
  .title {
    padding: 10px 15px;
    background: linear-gradient(90deg, #8ad9de, rgba(255, 255, 255, 0));
    color: #000;
    display: flex;
    align-items: center;
    .tt {
      margin-right: 10px;
    }
  }
  .content {
    padding: 10px 0 10px 15px;
    min-width: 240px;
    height: 150px;
  }
  .address {
    padding: 7px 0;
  }
}
</style>
