<template>
  <div class="area-oxy-project">
    <div class="column-container">
      <img :src="adimg" class="ad-img" v-if="false" />
      <CpnTitle icon="tt-5" more="/brandPicList?brandType=3"
        >中国天然氧吧
        <KOSelect
          v-if="false"
          class="other"
          v-model="year.value"
          :options="year.options"
          @change="EventChange"
        ></KOSelect
      ></CpnTitle>
      <CpnProject
        :brandType="3"
        :list="list"
        v-loading="loading"
        element-loading-background="rgba(255, 255, 255, 0.5)"
      ></CpnProject>
    </div>
  </div>
</template>
<script>
import CpnTitle from '@/components/view/title/CpnTitle.vue'
import KOSelect from '@/components/common/form/select/KOSelect.vue'
import CpnProject from '@/components/view/project/CpnProject.vue'
import { Info_brandProductList, Info_brandYear } from '@/info/infoHome.js'

export default {
  inject: [],
  components: { CpnTitle, KOSelect, CpnProject },
  props: {},
  data() {
    return {
      loading: false,
      adimg: require('@/assets/img/home/ad-1.jpg'),
      year: {
        options: [{ name: '请选择', value: '' }],
        value: ''
      },
      list: [],
      bodyWidth: document.documentElement.clientWidth
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.initYear()
    this.initData()
    this.getData_year()
  },
  methods: {
    getData_year() {
      Info_brandYear({ brandType: 3 }).then((ret) => {
        this.year.options = []
        if (ret.data?.length) {
          for (let item of ret.data) {
            this.year.options.push({
              name: item.buildDate,
              value: item.buildDate
            })
          }
          this.year.options = this.year.options.reverse()
          this.year.value = this.year.options[0].value
        }
      })
    },
    initYear() {
      let now = new Date().Format('YYYY')
      for (let i = 0; i < 10; i++) {
        this.year.options.push({ name: now - i + '年', value: now - i })
      }
    },
    initData() {
      let pageSize = 4
      this.loading = true
      Info_brandProductList({
        brandType: 3,
        pageNum: 1,
        pageSize: pageSize,
        year: this.year.value
      }).then((ret) => {
        this.loading = false
        this.list = ret?.list.map((e) => {
          let imgs = this.Api.jsonData(e.imgs)
          let img = imgs[0]?.url
          return { ...e, img: img ? this.Api.getImgPath() + img : '' }
        })
      })
    },
    EventChange() {
      this.initData()
    }
  }
}
</script>
<style lang="scss" scoped>
.area-oxy-project {
  .ad-img {
    width: 100%;
    margin-bottom: 50px;
    transition: 0.3s ease-out;
    &:hover {
      transform: scale(1.05); /**hover放大图片 */
    }
  }
  .other {
    position: absolute;
    right: 50px;
    top: 0;
    display: flex;
    align-items: center;
    width: 100px;
  }
}
</style>
